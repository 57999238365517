import { useEffect, useState } from "react"

function useSSR() {
    const [isSSR, setIsSSR] = useState(false)

    useEffect(() => {
        if(typeof window !== 'undefined') {
            setIsSSR(false)
        }
        else {
            setIsSSR(true)
        }
    }, [])

    return isSSR
}

export default useSSR;
